import { FormField, Label, SelectV2, TextArea, TextInput } from '@dynatrace/strato-components-preview/forms';
import { Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { Borders, Colors } from '@dynatrace/strato-design-tokens';
import { DeleteIcon, DuplicateIcon, LinkIcon } from '@dynatrace/strato-icons';
import { Controller } from 'react-hook-form';
import { handleBrowseClick } from './utils';
import { hideElement } from '../ProjectStyles.css';
import { Chip } from '@dynatrace/strato-components-preview/content';
import attachment from '../../../../assets/attachment.svg';
import attachmentDisabled from '../../../../assets/attachment_disabled.svg';
import { useEffect } from 'react';

export interface RequestMessageProps {
  control: any;
  handleTemplateChange: (value: any) => void;
  requestTemplateData: any;
  fields: Record<'id', string>[];
  selectedFiles: File[][];
  handleFileRemove: (file: File, index: number) => void;
  handleAddMore: () => void;
  fileInputRefs: React.MutableRefObject<HTMLInputElement[]>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  removeFileSection: (index: number) => void;
  remove: (index: number) => void;
  handleAddDuplicate: (index: number) => void;
  editRequestDetails: any;
  updateProjectRequest: any;
  editRequestFlag: boolean;
  setEditRequestFlag: any;
  disableReqeustMessage: boolean;
}

export const RequestMessage = (props: RequestMessageProps) => {
  const {
    control,
    handleTemplateChange,
    requestTemplateData,
    fields,
    selectedFiles,
    handleFileRemove,
    handleAddMore,
    fileInputRefs,
    handleFileChange,
    handleAddDuplicate,
    removeFileSection,
    remove,
    editRequestDetails,
    updateProjectRequest,
    editRequestFlag,
    setEditRequestFlag,
  } = props;

  useEffect(() => {
    if (editRequestDetails && editRequestFlag) {
      updateProjectRequest(editRequestDetails);
      setEditRequestFlag(false);
    }
  }, [editRequestDetails]);

  return (
    <Flex flexDirection='column' gap={16}>
      <Flex flexDirection='row' width={'80%'}>
        <Controller
          name='template'
          control={control}
          rules={{
            required: {
              value: false,
              message: 'Please choose an option.',
            },
          }}
          render={({ field }) => (
            <FormField>
              <Label>Select from a template (optional)</Label>
              <Flex
                onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                  if (event.key === 'Enter') {
                    event.preventDefault(); // Prevent form submission
                  }
                }}
              >
                <SelectV2
                  {...field}
                  // onChange={(value) => onDocumentTemplateChange('template', value)}
                  onChange={(value) => {
                    field.onChange(value);
                    handleTemplateChange(value);
                  }}
                  disabled={editRequestDetails}
                >
                  <SelectV2.Trigger placeholder={'Select a template'} />
                  <SelectV2.Content width={'auto'}>
                    {requestTemplateData?.map(
                      (option: {
                        requestTemplateId: React.Key | null | undefined;
                        requestTemplateName:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined;
                      }) => (
                        <SelectV2.Option key={option.requestTemplateId} value={option.requestTemplateId}>
                          {option.requestTemplateName}
                        </SelectV2.Option>
                      ),
                    )}
                  </SelectV2.Content>
                </SelectV2>
              </Flex>
            </FormField>
          )}
        />
      </Flex>
      <Flex flexDirection='column' gap={8}>
        <Controller
          name='requestDescription'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Request description is required',
            },
            minLength: {
              value: 1,
              message: '',
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField required>
              <Label>Request description</Label>
              <TextInput
                {...field}
                value={field.value}
                style={{ width: '80%' }}
                placeholder='Enter your request description'
                // onChange={(value: any) => handleChange('requestName', value)}

                controlState={{
                  state: error ? 'error' : 'valid',
                  hint: error?.message || '',
                }}

              />
            </FormField>
          )}
        />
        <Controller
          name='requestMessage'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Message is required',
            },
            maxLength: {
              value: 4000,
              message: 'Message cannot have more than 4000 characters.',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormField required>
              <Label>Request message</Label>
              <TextArea
                {...field}
                value={field.value}
                placeholder='Enter your message'
                // onChange={(value: any) => handleChange('requestDescription', value)}
                width={'80%'}
                controlState={{
                  state: error ? 'error' : 'valid',
                  hint: error?.message || '',
                }}

              />
            </FormField>
          )}
        />
      </Flex>

      {fields.map((field, index) => {
        const disabledRequestDetail = 
        editRequestDetails && 
        editRequestDetails.projectRequestSentList &&
        editRequestDetails.projectRequestDetailList &&
        editRequestDetails.projectRequestSentList.some(
          (projectRequestSent: { projectRequestDetailsId: any; statusName: string; }) =>
            editRequestDetails.projectRequestDetailList[index]?.projectRequestDetailsId && // Check if the item has a valid ID
            projectRequestSent.projectRequestDetailsId === editRequestDetails.projectRequestDetailList[index].projectRequestDetailsId &&
            projectRequestSent.statusName !== "New"
        );

        return (
          <Flex
            key={field.id}
            flexDirection='row'
            style={{
              border: `1px solid ${Colors.Border.Neutral.Default}`,
              borderRadius: Borders.Radius.Field.Default,
              width: '80%',
            }}
            gap={16}
          >
            <Flex flexDirection='column' padding={12} gap={8} width={'100%'}>
              <Controller
                name={`requestDetails.${index}.label`}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'File name is required',
                  },
                  maxLength: {
                    value: 45,
                    message: 'File name cannot have more than 45 characters.',
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormField required>
                    <Label>File name</Label>
                    <TextInput
                      {...field}
                      value={field.value}
                      placeholder='Enter file name'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message || '',
                      }}
                      disabled={disabledRequestDetail}
                    />
                  </FormField>
                )}
              />
              <Controller
                name={`requestDetails.${index}.instructions`}
                control={control}
                // rules={{
                //   required: {
                //     value: true,
                //     message: 'Instruction is required',
                //   },
                // }}
                render={({ field, fieldState: { error } }) => (
                  <FormField>
                    <Label>Instructions</Label>
                    <TextArea
                      {...field}
                      value={field.value}
                      width={'100%'}
                      placeholder='Enter instructions'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: error?.message || '',
                      }}
                      disabled={disabledRequestDetail}
                    />
                  </FormField>
                )}
              />
              <Flex flexDirection='column'>
                {selectedFiles.length > 0 && (
                  <Flex flexDirection='column' rowGap={8}>
                    {selectedFiles[index] && selectedFiles[index].length > 0 && (
                      <Flex flexDirection='row' rowGap={8} flexWrap='wrap'>
                        {selectedFiles[index]?.map((file) => (
                          <Tooltip text={file.name}>
                            <Chip variant='emphasized' color='primary' disabled={disabledRequestDetail}>
                              {`${file.name} (${(file.size / 1024).toFixed(2)} kB)`}
                              <Chip.DeleteButton onClick={() => handleFileRemove(file, index)} disabled={disabledRequestDetail} />
                            </Chip>
                          </Tooltip>
                        ))}
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Flex
              flexDirection={'column'}
              width={50}
              justifyContent='flex-start'
              alignItems='center'
              padding={16}
              style={{ borderLeft: 'solid', borderWidth: '1px', borderColor: Colors.Border.Neutral.Default }}
            >
              <Tooltip text={'Attach files'}>
                <Button variant='emphasized' onClick={() => handleBrowseClick(fileInputRefs, index)} disabled={disabledRequestDetail}>
                  <img src={disabledRequestDetail ? attachmentDisabled : attachment} alt={'Attachment'} height='19px' width='14px' />
                </Button>
              </Tooltip>
              <input
                type='file'
                ref={(el) => (fileInputRefs.current[index] = el!)} // Use refs for each input
                className={hideElement}
                onChange={(event) => handleFileChange(event, index)}
                multiple // Allow multiple file selection
                title='Attach files'
                disabled={disabledRequestDetail}
              />

              <Tooltip text={'Duplicate'}>
                <Button variant='emphasized' aria-label='Duplicate' onClick={() => handleAddDuplicate(index)}>
                  <DuplicateIcon />
                </Button>
              </Tooltip>
              <Tooltip text={'Delete'}>
                <Button
                  disabled={fields.length === 1 || disabledRequestDetail}
                  variant='emphasized'
                  aria-label='Remove'
                  onClick={() => {
                    remove(index);
                    removeFileSection(index);
                    if (editRequestDetails) {
                      editRequestDetails.projectRequestDetailList.splice(index, 1);
                    }
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        )
      })}
      <Flex flexDirection='row' gap={8} justifyContent='flex-end' width={'80%'}>
        <Button style={{ color: Colors.Text.Primary.Default }} onClick={handleAddMore} >
          Add more
        </Button>
      </Flex>
    </Flex>
  );
};
