import { Button, Flex } from '@dynatrace/strato-components';
import { Page, TitleBar } from '@dynatrace/strato-components-preview/layouts';
import { Tabs, Tab } from '@dynatrace/strato-components-preview/navigation';
import { PlusIcon, RefreshIcon } from '@dynatrace/strato-icons';
import { useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import { ProjectService } from '../../../services/ProjectService';
import { PackageDetails, RequestDetails } from '../../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { NotesDetails } from '../../../types/Notes';
import CreateRequest from '../request/CreateRequest';
import { configRoutes } from '../../../configs/constants';
import { useProjectPackagesById, useProjectRequestById, useProjectWithDetailsID, useStatusByTenant } from '../../../hooks/use-minerva-data';
import { groupDocumentsByProjectRequestId, GroupedDocuments, groupPackagesByProjectId, ProjectPackageDetails } from './project-details-utils';
import { LoadingStateComponent } from '../../../components/LoadingStateComponent';
import { ProjectPeopleRelation } from '../../../types/Project';
import { Requests } from './Requests/Requests';
import { ProjectDetails } from './ProjectDetails';
import { Colors } from '@dynatrace/strato-design-tokens';
import { Notes } from './Notes';
import { useAppInfo } from '../../../contexts/AppContext';
import { FormField, Label, Modal, TextArea } from '@dynatrace/strato-components-preview';
import { useForm, Controller } from 'react-hook-form';
import { FileView } from './Requests/FileView';
import CreatePackage from '../package/CreatePackage';
import { PackageView } from './Requests/packagesView';

export interface ProjectRequestDetailsProps { }

export const ProjectRequestDetails = () => {
  const { ProjectId } = useParams();

  const [isSidebarDismissed, setIsSidebarDismissed] = useState<boolean>(false);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [createRequestSheet, setCreateRequestSheet] = useState(false);
  const [createPackageSheet, setCreatePackageSheet] = useState(false);
  const [loading, setLoading] = useState(false);
  const peopleList = useRef<ProjectPeopleRelation[]>([]);
  const notes = useRef<NotesDetails[]>([]);
  const projectRequests = useRef<RequestDetails[]>([]);
  const projectPackages = useRef<ProjectPackageDetails[]>([]);
  const [projectRequestsApiData, setProjectRequestsApiData] = useState<GroupedDocuments[]>([] as GroupedDocuments[]);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentData, setCommentData] = useState({ projectRequestSentId: '', statusAutoId: '' });
  const { control, handleSubmit, reset } = useForm();
  const [selectedRows, setSelectedRows] = useState([]);

  const { tenantId } = useAppInfo();
  // get project details
  const {
    isLoading: projectDetailsLoading,
    isRefetching: projectDetailsRefetching,
    data: projectDetailsData,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useProjectWithDetailsID(Number(ProjectId), tenantId);

  if (projectDetailsError) {
    ShowErrorNotification('Error loading project details', projectDetailsError);
  }

  // get project requests custom hook
  const {
    isLoading: projectRequestsLoading,
    isRefetching: projectRequestsRefetching,
    data: projectRequestsData,
    error: requestError,
    refetch: refetchProjectRequests,
  } = useProjectRequestById(Number(ProjectId), tenantId);
  if (requestError) {
    ShowErrorNotification('Error loading project requests', requestError);
  }

  const {
    isLoading: projectPackagesLoading,
    isRefetching: projectPackagesRefetching,
    data: projectPackagesData,
    error: projectPackagesError,
    refetch: refetchProjectPackages,
  } = useProjectPackagesById(Number(ProjectId), tenantId);

  if (projectDetailsError) {
    ShowErrorNotification('Error loading project packages', projectPackagesError);
  }
  // get status Details
  const {
    isLoading: requestStatusLoading,
    data: requestStatusData,
    error: requestStatusError,
  } = useStatusByTenant(tenantId, 1);

  if (requestStatusError) {
    ShowErrorNotification('Error loading request status details', requestStatusError);
  }

  const loadingDetails =
    projectRequestsLoading || projectPackagesLoading || projectDetailsLoading || requestStatusLoading || projectDetailsRefetching || projectRequestsRefetching || loading;

    console.log("projectPackagesData",projectPackagesData)
  if (!loadingDetails) {
    //notes
    projectRequests.current = projectRequestsData?.projectRequest;
    projectPackages.current = projectPackagesData?.projectPackages;
    peopleList.current = projectDetailsData?.projectPeopleRelation ?? [];

    const notesDesc = projectDetailsData?.notes?.sort(
      (a: any, b: any) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );
    notes.current = notesDesc || [];

    //requests
    const projectRequestsWithDocuments = groupDocumentsByProjectRequestId(projectRequestsData.projectRequest);
    
    
    const projectRequestsWithDocumentsDesc = projectRequestsWithDocuments.sort(
      (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );
    if (JSON.stringify(projectRequestsApiData) !== JSON.stringify(projectRequestsWithDocumentsDesc)) {
      setProjectRequestsApiData(projectRequestsWithDocumentsDesc);
    }
      
    
  }

  
  const handleCreatePackage = async () => {
    refetchProjectPackages();
    refetchProjectDetails();
    
  };

  const handleCreateRequest = async (newRequest: any) => {
    // setProjectRequestsApiData((prev: any) => [newRequest, ...prev]);
    refetchProjectDetails();
    refetchProjectRequests();
  };

  
  const CreatePackageClick = () => {
    setCurrentTabIndex(0);
    setCreatePackageSheet(true);
  };

  const CreateRequestClick = () => {
    setCurrentTabIndex(0);
    setCreateRequestSheet(true);
  };

  
  const handleSelectRows = (data: any) => {
    setSelectedRows(data)
  }

  const updateStatus = async (comment: string | null, projectRequestSentId: string, statusAutoId: string) => {
    const request = {
      projectRequestSentId,
      statusAutoId,
      comments: comment
    };

    try {
      const data = await ProjectService.updateProjectRequestStatus(request, tenantId, Number(ProjectId));
      if (data) {
        refetchProjectRequests();
        ShowSuccessNotification('Status updated successfully');
      }
    } catch (error) {
      ShowErrorNotification('Error updating status', error);
    }
  };

  const handleSelectChange = async (updatedStatus: string, statusOptions: any, cell: any) => {
    const selectedOption = statusOptions.find((option: { statusName: string }) => option.statusName === updatedStatus);
    const statusAutoId = selectedOption?.statusAutoId ?? '';

    if (updatedStatus === 'Rejected') {
      setCommentData({
        projectRequestSentId: cell.projectRequestSentId,
        statusAutoId
      });
      setIsCommentModalOpen(true);
    } else {
      await updateStatus(null, cell.projectRequestSentId, statusAutoId);
    }
  };

  const handleModalSubmit = async (data: any) => {
    const requestComment = data.requestComment || "";
    await updateStatus(requestComment, commentData.projectRequestSentId, commentData.statusAutoId);
    setCommentData({ projectRequestSentId: '', statusAutoId: '' });
    setIsCommentModalOpen(false);
    reset({ requestComment: '' });
  };

  const handleModalClose = () => {
    setIsCommentModalOpen(false);
    reset({ requestComment: '' });
  }

  const refreshRequests = () => {
    setCurrentTabIndex(0);
    refetchProjectDetails();
    refetchProjectRequests();
  };

  const onSidebarDismissChange = () => {
    setIsSidebarDismissed(!isSidebarDismissed);
  };

  return (
    <>
      <CreateRequest
        isOpen={createRequestSheet}
        onClose={function (): void {
          setCreateRequestSheet(!createRequestSheet);
        }}
        peopleList={peopleList.current}
        projectDetails={projectDetailsData}
        onRequestCreated={handleCreateRequest}
      />
       <CreatePackage
        isOpen={createPackageSheet}
        onClose={function (): void {
          setCreatePackageSheet(!createPackageSheet);
        }}
        peopleList={peopleList.current}
        projectDetails={projectDetailsData}
        onPackageCreated={handleCreatePackage}
      />
      <TitleBar>
        <TitleBar.Prefix>
          <Page.PanelControlButton target='sidebar' />
        </TitleBar.Prefix>
        <TitleBar.Title>{projectDetailsData?.project?.projectName ?? ''}</TitleBar.Title>
        {/* <TitleBar.Subtitle>{configRoutes[3].desc} </TitleBar.Subtitle> */}
        <TitleBar.Suffix>
          <Flex gap={8}>
            {/* <Button
              onClick={() => CreatePackageClick()}
              variant='accent'
              color='primary'
              style={{ margin: '0px 0px 0px auto' }}
            >
              <Button.Prefix>
                <PlusIcon />
              </Button.Prefix>
              Create a package
            </Button> */}
            <Button variant='emphasized' color='neutral' onClick={refreshRequests}>
              <Button.Prefix>
                <RefreshIcon />
              </Button.Prefix>
              Refresh
            </Button>
          </Flex>
        </TitleBar.Suffix>
      </TitleBar>
      <LoadingStateComponent loading={loadingDetails || projectRequestsRefetching} />
      <Modal show={isCommentModalOpen} title="Reject" size="small" onDismiss={handleModalClose}>
        <form onSubmit={handleSubmit(handleModalSubmit)} noValidate>
          <Flex flexDirection="column" gap={16}>
            <Controller
              name="requestComment"
              control={control}
              render={({ field, fieldState }) => (
                <FormField>
                  <Label>Reason for rejection</Label>
                  <TextArea
                    {...field}
                    placeholder="Enter comment for rejection"
                    width={'100%'}
                    controlState={{
                      state: fieldState.error ? 'error' : 'valid',
                      hint: fieldState.error?.message,
                    }}
                  />
                </FormField>
              )}
            />
            <>
              <Flex justifyContent='flex-end' gap={4}>
                <Button variant="default" onClick={handleModalClose}>Cancel</Button>
                <Button type="submit" variant="accent" color='primary'>Confirm</Button>
              </Flex>
            </>
          </Flex>
        </form>
      </Modal>

      <Page style={{ height: 'calc(100% - 65px)' }}>
        <Page.Sidebar
          dismissed={isSidebarDismissed}
          //onDismissChange={setIsSidebarDismissed}
          preferredWidth={275}
          style={{ backgroundColor: Colors.Background.Base.Default }}
        >
          <ProjectDetails
            project={projectDetailsData?.project}
            projectStatus={projectDetailsData?.status}
            projectLoan={projectDetailsData?.loanType}
            projectPeopleRelation={projectDetailsData?.projectPeopleRelation ?? []}
            projectBusinessesRelation={projectDetailsData?.projectBusinessesRelation ?? []}
            refetchProjectDetails={refetchProjectDetails}
            onSidebarDismissChange={onSidebarDismissChange}
          />
        </Page.Sidebar>
        <Page.Main>
          <Flex flexDirection={'column'}>
            <Page.PanelControlButton onClick={onSidebarDismissChange} target='sidebar' style={{ display: isSidebarDismissed ? 'block' : 'none', position: 'absolute', top: '15px', left: '0px' }} />
            <Tabs
              selectedIndex={currentTabIndex}
              style={{ position: 'relative', left: isSidebarDismissed ? '20px' : 0, width: isSidebarDismissed ? 'calc(100% - 20px)' : '100%' }}
              onChange={setCurrentTabIndex}
            >
              <Tab title='Requests'>
                <Flex justifyContent="space-between" alignItems="end" style={{ marginBottom: '16px' }}>
                  <Button
                    onClick={() => CreateRequestClick()}
                    variant='accent'
                    color='primary'
                    style={{ margin: '0px 0px 0px auto' }}
                  >
                    <Button.Prefix>
                      <PlusIcon />
                    </Button.Prefix>
                    Create a request
                  </Button>
                </Flex>
                <Requests
                  projectDetails={projectDetailsData}
                  onRequestUpdated={handleCreateRequest}
                  projectRequestsApiData={projectRequestsApiData}
                  requestStatusData={requestStatusData?.statuses}
                  handleSelectChange={handleSelectChange}
                  projectRequests={projectRequests.current}
                  setProjectRequestsApiData={setProjectRequestsApiData}
                  refetchProjectRequests={refetchProjectRequests}
                  peopleList={peopleList.current}
                  setLoading={setLoading}
                />
              </Tab>
              <Tab title='Files'>
                <FileView
                  onRequestUpdated={handleCreateRequest}
                  projectRequests={projectRequests.current}
                  refetchProjectRequests={refetchProjectRequests}
                  setLoading={setLoading}
                />
              </Tab>
              <Tab title='Packages'>
                <PackageView 
                onPackageUpdated={handleCreatePackage}
                projectPackages={projectPackages.current}
                refetchProjectRequests={refetchProjectRequests}
                peopleList={peopleList.current}
                setLoading={setLoading}
                projectDetails= {projectDetailsData}
                />
              </Tab>
              <Tab title='Notes'>
                <Notes projectId={projectDetailsData?.project?.projectId ?? 0} notes={notes.current} refetch={refetchProjectDetails} />
              </Tab>
            </Tabs>
            <Flex>&nbsp;</Flex>
          </Flex>
        </Page.Main>
      </Page>
    </>
  );
};