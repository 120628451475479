import { Modal, Tooltip } from '@dynatrace/strato-components-preview/overlays';
import { DataTable, TableRowActions } from '@dynatrace/strato-components-preview/tables';
import { Button } from '@dynatrace/strato-components/buttons';
import { Flex } from '@dynatrace/strato-components/layouts';
import { DownloadIcon, UploadIcon, FeedbackIcon, XmarkIcon, CheckmarkIcon, CopyIcon, SuccessIcon, ExternalLinkIcon } from '@dynatrace/strato-icons';
import { columns, rowColumns, rowDocumentsColumns } from './table-definitions';
import { Dispatch, useRef, useState } from 'react';
import { RequestDetails } from '../../../../types/Request';
import { ProjectService } from '../../../../services/ProjectService';
import { GroupedDocuments, handleFileChange } from '../project-details-utils';
import { hideElement } from '../../ProjectStyles.css';
import { StatusDetails } from '../../../../types/Status';
import '../../../../App.css'
import { useAppInfo } from '../../../../contexts/AppContext';
import { Strong } from '@dynatrace/strato-components';
import CreateRequest from '../../request/CreateRequest';
import { ShowErrorNotification } from '../../../../utils/Notifications';
export interface RequestsProps {
  projectRequestsApiData: any;
  requestStatusData: StatusDetails[];
  handleSelectChange: ((updatedStatus: string, statusOptions: any, cell: any) => void);
  projectRequests: RequestDetails[];
  setProjectRequestsApiData: Dispatch<React.SetStateAction<GroupedDocuments[]>>;
  refetchProjectRequests: () => void;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  projectDetails: any;
  onRequestUpdated: any;
  peopleList: any;
}

export const Requests = (props: RequestsProps) => {
  const {
    projectDetails,
    onRequestUpdated,
    projectRequestsApiData,
    requestStatusData,
    projectRequests,
    handleSelectChange,
    setProjectRequestsApiData,
    refetchProjectRequests,
    peopleList,
    setLoading,
  } = props;
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [copiedRequestId, setCopiedRequestId] = useState<number | null>(null);
  const [selectedComment, setSelectedComment] = useState<string | null>(null);
  const [requestCommentModalOpen, setRequestCommentModalOpen] = useState(false);
  const [createRequestSheet, setCreateRequestSheet] = useState(false);
  const [editProjectRequest, setEditProjectRequest] = useState<any>({});
  const { tenantId } = useAppInfo();

  const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});

  const downloadFileClick = function (rowInfo: any) {
    //In Local, uncomment the below line
    // window.open(ProjectService.getDownloadFileHeader(rowInfo.original.downloadFilePath));
    //In Dev, uncomment the below Line
    const downloadFilePath = rowInfo.downloadFilePath.split('uploads/')[1];
    window.open(ProjectService.getDownloadFileHeader(downloadFilePath, tenantId));
  };

  const handleUploadClick = (requestId: number, projectRequestSentId: number, documentIndex: number) => {
    if (fileInputRefs.current[requestId] && fileInputRefs.current[requestId][documentIndex]) {
      fileInputRefs.current[requestId][documentIndex]!.click();
    }
  };

  const openCommentModal = (comment: string | null) => {
    setSelectedComment(comment);
    setCommentModalOpen(true);
  };

  const closeCommentModal = () => {
    setCommentModalOpen(false);
    setSelectedComment(null);
  };

  const handleCopy = async (projectRequestUrl: string, rowId: number) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
    await navigator.clipboard.writeText(fullUrl);
    setCopiedRequestId(rowId);
    setTimeout(() => setCopiedRequestId(null), 2000);
  };

  const handleRedirect = async (projectRequestUrl: string, rowId: number) => {
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/upload/${projectRequestUrl}`;
    window.open(fullUrl, '_blank');
  };


  const RowColumnsWithActions = [
    ...rowColumns.slice(0, 1),
    {
      id: 'actions',
      header: 'Actions',
      accessor: 'projectRequestUrl',
      width: 100,
      Cell: ({ row }: any) => (
        <Flex justifyContent="flex-start">
          <Tooltip text={copiedRequestId === row.id ? 'Copied' : 'Copy upload page URL'}>
            <Button onClick={() => handleCopy(row.original.projectRequestUrl, row.id)}>
              {copiedRequestId === row.id ? <SuccessIcon /> : <CopyIcon />}
            </Button>
          </Tooltip>

          <Tooltip text={'Open upload page URL'}>
            <Button onClick={() => handleRedirect(row.original.projectRequestUrl, row.id)}>
              <ExternalLinkIcon />
            </Button>
          </Tooltip>
        </Flex>
      ),
    },
    ...rowColumns.slice(1),
  ];

  const openRequestCommentModal = (comment: string | null) => {
    setSelectedComment(comment);
    setRequestCommentModalOpen(true);
  };

  const closeRequestCommentModal = () => {
    setRequestCommentModalOpen(false);
    setSelectedComment(null);
  };

  const handleRequestEditChange = async (requestRow: any) => {
    try {
      setLoading(true);
      const data: any = await ProjectService.getProjectRequestByRequestId(requestRow.projectRequestId, requestRow.projectId, tenantId);
      if (data) {

      }
      setEditProjectRequest(data);
      setCreateRequestSheet(true);
    }
    catch (err) {
      ShowErrorNotification('Failed to load Request ' + requestRow.projectRequestName, err);
    }
    finally {
      setLoading(false);
    }


  }
  const rowDocumentsColumnsWithActions = (requestId: any) => [
    ...rowDocumentsColumns,
    {
      id: 'actions',
      header: 'Actions',
      width: 250,
      Cell: ({ row }: any) => {
        const documentIndex = Number(row.original.projectRequestSentId);
        //In Local use the below line
        // const requestRow: any = documentRow.original.original;
        //In Dev use the below line
        const requestRow = row.original;
        if (!fileInputRefs.current[requestId][documentIndex]) {
          fileInputRefs.current[requestId][documentIndex] = null;
        }
        return (
          <Flex justifyContent="flex-start">
            <input
              type='file'
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .zip"
              ref={(el) => (fileInputRefs.current[requestId][documentIndex] = el)}
              className={hideElement}
              onChange={(event) => handleFileChange(
                event,
                requestId,
                requestRow,
                documentIndex,
                projectRequests,
                projectRequestsApiData,
                setProjectRequestsApiData,
                refetchProjectRequests,
                setLoading
              )}
              title='Upload file' />
            <Tooltip text="Approve">
              <Button
                onClick={() => handleSelectChange('Approved', requestStatusData, requestRow)}
                disabled={requestRow.statusName === 'Approved' || requestRow.statusName === 'New'}
              >
                <Button.Prefix>
                  <CheckmarkIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text="Reject">
              <Button
                onClick={() => handleSelectChange('Rejected', requestStatusData, requestRow)}
                disabled={requestRow.statusName === 'Approved' || requestRow.statusName === 'New' || requestRow.statusName === 'Rejected'}
              >
                <Button.Prefix>
                  <XmarkIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text={'Upload document'}>
              <Button
                onClick={(requestRow: any) => handleUploadClick(requestId, requestRow as any, documentIndex)}
                disabled={requestRow.statusName === 'Approved'}
              >
                <Button.Prefix>
                  <UploadIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text={'Download document'}>
              <Button
                onClick={() => {
                  // const item = documentRow.original;
                  downloadFileClick(row.original);
                }}
                disabled={requestRow.statusName === 'New' || requestRow.statusName === 'Rejected' || requestRow.statusName === 'Not Applicable' || requestRow.downloadFilePath === '' || requestRow.downloadFilePath=== null}
              >
                <Button.Prefix>
                  <DownloadIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
            <Tooltip text={'Show comments'}>
              <Button onClick={() => openCommentModal(String(requestRow.comments))} disabled={requestRow.comments === null || requestRow.comments === "" || (requestRow.statusName !== "Not Applicable" && requestRow.statusName !== "Rejected")}>
                <Button.Prefix>
                  <FeedbackIcon />
                </Button.Prefix>
              </Button>
            </Tooltip>
          </Flex>
        )
      },
    },
  ]

  return (
    <><CreateRequest
      isOpen={createRequestSheet}
      onClose={function (): void {
        setCreateRequestSheet(!createRequestSheet);
      }}
      peopleList={peopleList}
      projectDetails={projectDetails}
      onRequestCreated={onRequestUpdated}
      editRequestDetails={editProjectRequest} />
      <Flex flexDirection='column' style={{ overflow: 'auto' }}>
        {projectRequestsApiData !== undefined && (
          <DataTable
            columns={columns(handleRequestEditChange)}
            data={projectRequestsApiData.filter((item: any) => item.assignedList[0].documents[0].documentTypeName !== "Project Documents")}
            sortable
            variant={{ rowDensity: 'default' }}
            sortBy={{ id: 'createdOn', desc: true }}
            style={{ overflowY: 'auto' }}
            resizable
          >
            <DataTable.UserActions>
              <DataTable.RowActions>
                {(row) => {
                  // const documentIndex = Number(row.index);
                  // const requestId = row.projectRequestId;
                  //In Local use the below line
                  // const requestRow: any = documentRow.original.original;
                  //In Dev use the below line
                  const requestRow = row.original;
                  // if (!fileInputRefs.current[requestId][documentIndex]) {
                  //   fileInputRefs.current[requestId][documentIndex] = null;
                  // }
                  return (
                    <><Modal
                      show={requestCommentModalOpen}
                      title="requestComments"
                      size="small"
                      onDismiss={closeRequestCommentModal}
                    >
                      <Flex flexDirection="column" gap={8} width={512}>
                        <Strong>{selectedComment}</Strong>
                        <Flex justifyContent="flex-end">
                          <Button variant="accent" onClick={closeRequestCommentModal} type="button">
                            Close
                          </Button>
                        </Flex>
                      </Flex>
                    </Modal><TableRowActions.Group>
                        <Tooltip text={'Show comments'}>
                          <Button onClick={() => openRequestCommentModal(String(requestRow.requestUpdateComments))} disabled={requestRow.requestUpdateComments === null || requestRow.requestUpdateComments === ""}>
                            <Button.Prefix>
                              <FeedbackIcon />
                            </Button.Prefix>
                          </Button>
                        </Tooltip>
                      </TableRowActions.Group></>
                  );
                }}
              </DataTable.RowActions>
            </DataTable.UserActions>
            <DataTable.ExpandableRow>
              {({ row }) => {
                const requestId = row.projectRequestId;
                // const projectRequestSentId = row.projectRequestSentId;
                if (!fileInputRefs.current[requestId]) {
                  fileInputRefs.current[requestId] = [];
                }
                return (
                  <Flex flexDirection='column'>
                    <Modal
                      show={commentModalOpen}
                      title="Comments"
                      size="small"
                      onDismiss={closeCommentModal}
                    >
                      <Flex flexDirection="column" gap={8} width={512}>
                        <Strong>{selectedComment}</Strong>
                        <Flex justifyContent="flex-end">
                          <Button variant="accent" onClick={closeCommentModal} type="button">
                            Close
                          </Button>
                        </Flex>
                      </Flex>
                    </Modal>
                    <DataTable
                      columns={RowColumnsWithActions as any}
                      data={row.assignedList}
                      variant={{ rowDensity: 'default', contained: false }}
                    >
                      <DataTable.ExpandableRow>
                        {(assignedRow) => {
                          // const assignedIndex = Number(assignedRow.row.index);
                          return (
                            <Flex flexDirection='column' width={'100%'}>
                              <DataTable
                                columns={rowDocumentsColumnsWithActions(requestId) as any}
                                data={assignedRow.row.documents}
                                variant={{ rowDensity: 'default', contained: false }}
                                resizable
                              >
                              </DataTable>
                            </Flex>
                          );
                        }}
                      </DataTable.ExpandableRow>
                    </DataTable>
                  </Flex>
                );
              }}
            </DataTable.ExpandableRow>
          </DataTable>
        )}
      </Flex></>
  );
};