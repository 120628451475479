import { Dispatch } from 'react';
import { NotesDetails } from '../../../types/Notes';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { RequestDetails } from '../../../types/Request';
import { UploadService } from '../../../services/UploadService';
import { ProjectPackages } from '../../../types/packages';


export type Document = {
  label: string;
  instructions: string;
  documentTypeName: string;
  assignedto: string;
  statusName: string;
  comments: string;
  downloadFilePath: string;
  projectRequestSentId: number;
  projectRequestId: number;
  projectId: number;
  tenantId: number;
  documentTypeAutoId: number;
  projectRequestDetailsFileId: number;
  projectRequestDetailsId: number;
  filePath: string;
};

export type AssignedList = {
  assignedto: string;
  completed: number;
  inProgress: number;
  projectRequestUrl: string;
  documents: Document[];
};

export type GroupedDocuments = {
  projectId: number;
  tenantId: number;
  projectRequestId: number;
  requestUpdateComments: string;
  projectRequestName: string;
  projectRequestDescription: string;
  createdOn: string;
  assignedCount: number;
  assignedList: AssignedList[];
};

export const Notes = (
  newNotes: boolean,
  setNewNotes: React.Dispatch<React.SetStateAction<boolean>>,
  AddNewNotesClick: () => void,
  notes: NotesDetails[],
  refetch: any,
  addNewNotes: string,
  setAddNewNotes: React.Dispatch<React.SetStateAction<string>>,
  truncateNotesText: any,
) => { };

export const truncateText = (text: string, maxLines: number) => {
  const lines = text.split('. ');
  if (lines.length <= maxLines) {
    return text;
  }
  return lines.slice(0, maxLines).join('\n') + '...';
};

export const groupDocumentsByProjectRequestId = (projectRequests: RequestDetails[]) => {
  const grouped: { [key: number]: GroupedDocuments } = {};

  projectRequests.forEach((item) => {
    const {
      projectRequestId,
      projectId,
      tenantId,
      requestUpdateComments,
      projectRequestName,
      projectRequestDescription,
      createdOn,
      label,
      instructions,
      documentTypeName,
      assignedto,
      statusName,
      comments,
      downloadFilePath,
      projectRequestSentId,
      projectRequestUrl,
      documentTypeAutoId,
      projectRequestDetailsFileId,
      projectRequestDetailsId,
      filePath,
    } = item;

    if (!grouped[projectRequestId]) {
      grouped[projectRequestId] = {
        projectRequestId,
        projectId,
        tenantId,
        requestUpdateComments,
        projectRequestName,
        projectRequestDescription,
        assignedCount: 1,
        createdOn,
        assignedList: [],
      };
    }

    const assignedGroup = grouped[projectRequestId].assignedList.find((group) => group.assignedto === assignedto);

    if (assignedGroup) {
      assignedGroup.documents.push({
        label,
        instructions,
        documentTypeName,
        assignedto,
        statusName,
        comments,
        downloadFilePath,
        projectRequestSentId,
        projectRequestId,
        projectId,
        tenantId,
        documentTypeAutoId,
        projectRequestDetailsFileId,
        projectRequestDetailsId,
        filePath

      });
    } else {
      grouped[projectRequestId].assignedList.push({
        assignedto,
        completed: 0,
        inProgress: 0,
        projectRequestUrl,
        documents: [
          { label, instructions, documentTypeName, assignedto, statusName, comments, downloadFilePath, projectRequestSentId, projectRequestId, projectId, tenantId, documentTypeAutoId, projectRequestDetailsFileId, projectRequestDetailsId, filePath },
        ],
      });
    }
  });

  // Update documentsCount for each assigned group
  Object.values(grouped).forEach((project) => {
    const assignedCount = project.assignedList.length;
    project.assignedCount = assignedCount;
    project.assignedList.forEach((group) => {
      const inProgress = group.documents.filter(
        (doc) => doc.statusName !== 'Approved' && doc.statusName !== 'Rejected',
      ).length;
      group.inProgress = inProgress;
      const completed = group.documents.filter(
        (doc) => doc.statusName === 'Approved' || doc.statusName === 'Rejected',
      ).length;
      group.completed = completed;
    });
  });

  return Object.values(grouped);
};

export const handleFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  requestId: number,
  uploadRow: any,
  documentIndex: number,
  projectRequests: RequestDetails[],
  projectRequestsApiData: GroupedDocuments[],
  setProjectRequestsApiData: Dispatch<React.SetStateAction<GroupedDocuments[]>>,
  refetchProjectRequests: () => void,
  setLoading: Dispatch<React.SetStateAction<boolean>>,
) => {
  const MAX_FILE_SIZE = 100 * 1024 * 1024;
  const files = event.target.files;
  

  const uploadRequest = projectRequests.filter((e) => e.projectRequestSentId === uploadRow.projectRequestSentId)[0];
  projectRequestsApiData.map((request) => {
    if (request.projectRequestId === requestId) {
      return {
        ...request,
        assignedList: request.assignedList.map((assigned) => {
          if (assigned.documents.some((doc) => doc.projectRequestSentId === uploadRow.projectRequestSentId)) {
            return {
              ...assigned,
              documents: assigned.documents.map((doc) => {
                if (
                  doc.projectRequestSentId === uploadRow.projectRequestSentId &&
                  doc.documentTypeName === uploadRequest.documentTypeName
                ) {
                  return {
                    ...doc,
                    // Update any other necessary fields in the document here
                    statusName: 'Received',
                  };
                }
                return doc;
              }),
            };
          }
          return assigned;
        }),
      };
    }
    return request;
  });

  if (files && files.length > 0) {
    const file = files[0];


    if (file) {
      const allowedFileTypes = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'text/plain', // .txt
        'application/zip', // .zip
        'application/x-zip-compressed'
      ];
      const fileExt = file.name.split('.').pop();
      const isValid = allowedFileTypes.includes(file.type);
      if (!isValid) {
        event.target.value = '';
        ShowErrorNotification(`Invalid file type: ${fileExt || 'Unknown'}. Only file types of .jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt and .zip are allowed.`)
      }
      const newFileSize = file.size;
      const fileSizeExceeded = !Boolean(newFileSize > MAX_FILE_SIZE);
      if (!fileSizeExceeded){
        event.target.value = '';
        ShowErrorNotification(`File size exceeded: ${file.name || 'Unknown'} has exceeded the file size limit of 100 MB.`)
      }
      else {
        try {
          setLoading(true);
          const reader = new FileReader();
          reader.onload = async () => {
            const fileDataUrl = reader.result as string;
            const base64Data = fileDataUrl.split(',')[1];
            const fileExtension = fileExt;
            const token = '';
            const tenantId = uploadRequest.tenantId;
            const projectId = uploadRequest.projectId;
            const projectRequestId = uploadRequest.projectRequestId;
            const projectRequestSentId = uploadRequest.projectRequestSentId;
            const peopelId = uploadRequest.peopleId;
            const documentTypeAutoId = uploadRequest.documentTypeAutoId;
            const fileext = '.' + fileExtension;
            const fileName = `${file.name.split('.').slice(0, -1).join('.')}`;
            // const fileext = fileFormt[0]?.fileTypeName;
            const chunkSize = 15 * 1024 * 1024;
            const totalChunks = Math.ceil(base64Data.length / chunkSize);
            const chunkUploadPromises = [];
            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
              const start = chunkIndex * chunkSize;
              const end = Math.min(start + chunkSize, base64Data.length);
              const chunk = base64Data.slice(start, end);
              const params = {
                token: token,
                tenantId: tenantId,
                projectId: projectId,
                projectRequestId: projectRequestId,
                fileData: chunk, // Send only the chunk,
                projectRequestSentId: projectRequestSentId,
                peopelId: peopelId,
                documentTypeAutoId: documentTypeAutoId,
                fileext: fileext,
                fileName,
                chunkIndex, // Index of the current chunk
                totalChunks, // Total number of chunks
                modifiedOn: new Date().toISOString(),
              };
              chunkUploadPromises.push(UploadService.postProjectRequestUploadFiles(params, tenantId));
              
            }
            const data = await Promise.all(chunkUploadPromises);
            if (data) {
              ShowSuccessNotification('File uploaded successfully');
              refetchProjectRequests();

            }
          };

          reader.readAsDataURL(file);
        } catch (error) {
          ShowErrorNotification('Error while uploading file', error);
        } finally {
          setLoading(false);
          setProjectRequestsApiData((prevData) =>
            // eslint-disable-next-line react-hooks/exhaustive-deps
            prevData.map((request: any) => {
              if (request.projectRequestId === requestId) {
                return {
                  ...request,
                  assignedList: request.assignedList.map((assigned: { documents: any[] }) => {
                    if (assigned.documents.some((doc) => doc.projectRequestSentId === uploadRow.projectRequestSentId)) {
                      return {
                        ...assigned,
                        documents: assigned.documents.map((doc) => {
                          if (
                            doc.projectRequestSentId === uploadRow.projectRequestSentId &&
                            doc.documentTypeName === uploadRequest.documentTypeName
                          ) {
                            return {
                              ...doc,
                              // Update any other necessary fields in the document here
                              statusName: 'Received',
                            };
                          }
                          return doc;
                        }),
                      };
                    }
                    return assigned;
                  }),
                };
              } else {
                return request;
              }
            }),
          );
        }
      }

    }
  }
};

export interface ProjectPackageDetails {
  packageId: number;
  projectId: number;
  tenantId: number;
  requestUpdateComments: string | null;
  packageName: string;
  filePackageId: string | null;
  projectRequestSentId: number;
  projectRequestId: string | null;
  label: string;
  uniqueLink: string;
}

interface Package {
  packageId: number;
  packageName: string;
  label: string;
  uniqueLink: string;
  projectRequestSentId: number;
  filePackageId: string | null;
  projectRequestId: string | null;
}
interface GroupedPackage {
  projectId: number;
  tenantId: number;
  packageCount: number;
  packageList: Package[];
}

export const groupPackagesByProjectId = (projectPackages: ProjectPackageDetails[]) => {

  if (!projectPackages || projectPackages.length === 0) {
    return [];
  }

  const recipientKeys = ["sentToEmailAddress", "peopleId", "sentToUserName", "packageShareId", "packageRequestURL"];
  const fileKeys = ["projectRequestSentId", "uniqueLink", "label", "projectRequestId", "projectRequestName","updateDate", "createdBy"];
  const groupedMap = new Map<number, any>();

  projectPackages.forEach((pkg: any) => {
    if (!groupedMap.has(pkg.packageId)) {
      const commonFields: Record<string, any> = {};

      Object.keys(pkg).forEach((key: string) => {
        if (!fileKeys.includes(key) && !recipientKeys.includes(key)) {
          commonFields[key] = pkg[key];
        }
      });

      commonFields.assignedTo = [];

      groupedMap.set(pkg.packageId, commonFields);
    }

    const group = groupedMap.get(pkg.packageId);

    let assignedToItem = group.assignedTo.find(
      (item: any) => item.peopleId === pkg.peopleId
    );

    const requestPackage = {
      filePackageId: pkg.filePackageId,
      projectRequestSentId: pkg.projectRequestSentId,
      uniqueLink: pkg.uniqueLink,
      label: pkg.label,
      projectRequestId: pkg.projectRequestId,
      projectRequestName: pkg.projectRequestName,
      updateDate: pkg.updateDate,
      createdBy: pkg.createdBy,
      createdOn: pkg.createdOn,
    };

    if (assignedToItem) {
      assignedToItem.requestPackages.push(requestPackage);
      if (pkg.packageRequestURL) {
        assignedToItem.packageRequestURL = pkg.packageRequestURL;
      }
    } else {
      assignedToItem = {
        sentToEmailAddress: pkg.sentToEmailAddress,
        peopleId: pkg.peopleId,
        packageRequestURL: pkg.packageRequestURL,
        sentToUserName: pkg.sentToUserName,
        packageShareId: pkg.packageShareId,
        requestPackages: [requestPackage],
      };
      group.assignedTo.push(assignedToItem);
    }
  });

  const resultArray = Array.from(groupedMap.values());
  return resultArray;
};

