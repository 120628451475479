import { Flex } from '@dynatrace/strato-components/layouts';
import { Request } from '../../types/Request';
import { Strong, Text } from '@dynatrace/strato-components/typography';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@dynatrace/strato-components';

export interface RequestDetailsProps {
  request: Request | undefined;
}

export const RequestDetails = (props: RequestDetailsProps) => {
  const { request } = props;
  const [truncateTextFlag, setTruncateTextFlag] = useState(true);
  const truncatedText = request?.projectRequestDescription
  ? request.projectRequestDescription.slice(0, 1000)
  : '';

  return (
    <Flex flexDirection='column' gap={16}>
      {/* User Info Section */}
      <Flex flexDirection='column' className='userInfo' title={''}>
        {request && (
          <Flex justifyContent='space-between'>
            <Text as='h3' style={{ textAlign: 'left' }}>
              <strong>{request.projectRequestName}</strong>
            </Text>
            <Text as='h3' style={{ textAlign: 'right' }}>
              <strong>{request.projectRequestId}</strong>
            </Text>
          </Flex>
        )}
        <Flex style={{ backgroundColor: Colors.Background.Container.Primary.Default, padding: '5px' }}>
          <Flex flexDirection='column' justifyContent='flex-start' width={'100%'}>
            <Text style = {{lineHeight: '1.5', whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word'}} fontStyle={'text'} as='pre'>
              {truncateTextFlag && (
                
                  <Strong>{truncatedText} 
                  {truncatedText !== request?.projectRequestDescription && (
                    <Button onClick={() => setTruncateTextFlag(false)}>show more</Button>
                  )}
                  </Strong>
                  
                
              ) }
              {!truncateTextFlag && (
                <Flex>
                  <Strong>{request?.projectRequestDescription} {"  "} 
                  {truncatedText !== request?.projectRequestDescription && (
                    <Button onClick={() => setTruncateTextFlag(true)}>show less</Button>
                  )}
                  </Strong>
                  
                </Flex>
              )}
              
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
